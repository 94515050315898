import React, { useState, Fragment } from 'react';
import { InputBox, NextButton } from './common';
import { areAllNumbers } from './utils';
import './StepMeters.scss';

interface StepMetersInputsProps {
  onChange: Function;
}

const StepMeters = (): JSX.Element => {
  const [enable, setEnable] = useState<boolean>(false);

  const handleChange = (values: string[]): void => {
    const allNumbers = areAllNumbers(values);
    setEnable(allNumbers);
  };

  return (
    <article className="step-meters">
      <div className="row middle fit gut-xs">
        <p className="label">Medidas</p>
        <StepMetersInputs onChange={handleChange} />
        <div className="fit step__next-holder">
          <NextButton disabled={!enable} />
        </div>
      </div>
    </article>
  );
};

const StepMetersInputs = ({ onChange }: StepMetersInputsProps): JSX.Element => {
  const [values, setValues] = useState<string[]>(['', '']);
  const handleChange = (i: number) => {
    return (e: any) => {
      values.splice(i, 1, e.target.value);
      setValues([...values]);
      onChange([...values]);
    };
  };
  return (
    <Fragment>
      <div className="step-meters__input-holder">
        <InputBox
          name="meters.total"
          small
          type="number"
          min="10"
          max="9999"
          suffix="M²"
          onChange={handleChange(0)}
        >
          Totales
        </InputBox>
      </div>
      <div className="step-meters__input-holder">
        <InputBox
          name="meters.util"
          small
          type="number"
          min="10"
          max="9999"
          suffix="M²"
          onChange={handleChange(1)}
        >
          Útiles
        </InputBox>
      </div>
    </Fragment>
  );
};

StepMetersInputs.displayName = 'StepMetersInputs';
StepMeters.displayName = 'StepMeters';
export { StepMeters };
