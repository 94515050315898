import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import './Header.scss';

const Header = (): JSX.Element => {
  return (
    <header className="header">
      <div className="container-lg">
        <div className="row middle fit mar-sm">
          <a className="header__logo" href="#">
            <Logo />
          </a>
          <div className="header__nav mobi-display-no">
            <nav>
              <a href="#">Cómo funciona</a>
              <a href="#">Sobre nosotros</a>
            </nav>
          </div>
        </div>
        <div className="text-center">
          <p className="h5 text-light mar-no">
            Tasamos la propiedad que estás buscando
          </p>
          <p>comienza justo aquí, ingresando la dirección</p>
        </div>
      </div>
    </header>
  );
};

Header.displayName = 'Header';
export { Header };
