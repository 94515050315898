const API_URL = process.env.REACT_APP_API_URL ?? '';
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '';
const ENABLE_RECAPTCHA = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true';

const defaultResult = {
  detail: {
    title: "Aún no tenemos datos para propiedades aquí",
    parragraphs: [
      "Puedes buscar en la ciudad de Santiago, Chile."
    ]
  }
}

export const checkAddressCommune = async (gMapsAddressComponents:any) => {
  const headers: HeadersInit = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  const addressCommune = gMapsAddressComponents
    .find((component:any) =>  {
      return component.types.some((d:string) => d === "administrative_area_level_3")
    });

  if (addressCommune === undefined) {
    return { valid: false, ...defaultResult };
  }

  const communeName = addressCommune.short_name;
  const url = `${API_URL}/predict/check_commune?commune=${communeName}`;
  
  if (ENABLE_RECAPTCHA) {
    const recaptchaToken = await grecaptcha.enterprise.execute(
      RECAPTCHA_SITE_KEY, { action: 'PREDICT_PRICE' }
    );
    headers['X-ReCaptcha-Token'] = recaptchaToken;
    headers['X-ReCaptcha-Site-Key'] = RECAPTCHA_SITE_KEY;
  }

  const response = await fetch(url, { method: 'GET', headers });
  const { status } = response;

  if (status === 404) {
    const result = await response.json();
    return { valid: false, ...result };
  }

  return { valid: true };
};
