import { PredictionInput, PredictionOutput } from './schemas';
import { API_URL, ENABLE_RECAPTCHA, RECAPTCHA_SITE_KEY } from './environment';


export default async function predict(
  predictionInput: PredictionInput,
): Promise<PredictionOutput> {
  const body = JSON.stringify(predictionInput);
  let headers: HeadersInit = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  if (ENABLE_RECAPTCHA) {
    const recaptchaToken = await grecaptcha.enterprise.execute(
      RECAPTCHA_SITE_KEY, { action: 'PREDICT_PRICE' }
    );
    headers = {
      ...headers,
      'X-ReCaptcha-Token': recaptchaToken,
      'X-ReCaptcha-Site-Key': RECAPTCHA_SITE_KEY,
    }
  }
  const response = await fetch(`${API_URL}/predict`, {
    method: 'POST',
    headers,
    body,
  });
  const predictionOutput: PredictionOutput = await response.json();

  return predictionOutput;
}
