import React from 'react';
import './InputBox.scss';

interface InputBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  className?: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  flex?: boolean;
  suffix?: string;
}

const InputBox = ({
  children,
  className,
  small,
  medium,
  large,
  flex,
  suffix,
  ...attrs
}: InputBoxProps): JSX.Element => {
  const sizes: any[] = [
    small && 'input-box--small',
    medium && 'input-box--medium',
    large && 'input-box--large',
    flex && 'input-box--flex',
  ];
  attrs = { ...attrs, type: attrs.type || 'text' };
  className = ['input-box', ...sizes, className].filter(Boolean).join(' ');
  return (
    <label className={className}>
      <p className="input-box__label">{children}</p>
      <input className="input-box__input" {...attrs} />
      {suffix && <p className="input-box__suffix">{suffix}</p>}
    </label>
  );
};

InputBox.displayName = 'InputBox';
export { InputBox };
