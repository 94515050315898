import React, { useState } from 'react';
import { FormInput } from './FormInput';
import './Form.scss';

interface FormProps {
  visible: boolean;
  onSubmit: Function;
}

const Form = ({ visible, onSubmit }: FormProps): JSX.Element | null => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<any>({});
  const validate: any = {
    name: (value: string) => value.length > 2,
    email: (value: string) => /.+@.+\.[\w\d]{2,3}/.test(value),
  };
  const updateValue = (name: string) => {
    return (value: string) => setValues({ ...values, [name]: value });
  };
  const handleValidation = (name: string) => {
    return (value: string | undefined) =>
      value !== undefined && validate[name](value);
  };
  const allValids = Object.keys(validate).every((key) => {
    return handleValidation(key)(values[key]);
  });
  const className = ['newsletter-form', loading && 'newsletter-form--loading']
    .filter(Boolean)
    .join(' ');
  const handleSubmit = (e: any) => {
    const data = Object.fromEntries(new FormData(e.target));
    e.preventDefault();
    setLoading(true);
    onSubmit(data, () => {
      setLoading(false);
    });
  };

  return (
    (visible || null) && (
      <div className="container-md">
        <form className={className} onSubmit={handleSubmit}>
          <div className="row middle gut-sm">
            <div className="col-6-2">
              <FormInput
                name="name"
                label="Nombre"
                validate={handleValidation('name')}
                onChange={updateValue('name')}
              />
            </div>
            <div className="col-6-2">
              <FormInput
                name="email"
                label="Email"
                validate={handleValidation('email')}
                onChange={updateValue('email')}
              />
            </div>
            <div className="desk-fit">
              <button
                className="newsletter-form__submit"
                disabled={!allValids}
                type="submit"
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  );
};

Form.displayName = 'Form';
export { Form };
