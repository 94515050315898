import React, { useEffect, useState } from 'react';
import { PredictionFormData, StepProps } from 'api/schemas';
import geocodeFetch from 'api/geocode';
import {
  makeAsync,
  reduceData,
  checkAddressCommune,
  sendGAEvent,
} from './utils';
import { StepsNavigation } from './StepsNavigation';
import { StepPlace } from './StepPlace';
import { StepType } from './StepType';
import { StepMeters } from './StepMeters';
import { StepRooms } from './StepRooms';
import { StepResult } from './StepResult';
import { Step } from './Step';
import './Steps.scss';

interface Props {
  onChange: (index: number, data: PredictionFormData[]) => void;
}

const handleStepPlaceSubmit = async (data: PredictionFormData) => {
  const { place } = data;
  const geocode = await geocodeFetch(place?.id || '');
  const { valid, detail: validationMessage } = await checkAddressCommune(
    geocode.address_components,
  );

  return {
    ...data,
    place: { ...place, geocode: geocode, valid, validationMessage },
  };
};
const steps: StepProps[] = [
  {
    Component: StepPlace,
    className: 'step--in-middle',
    onSubmit: handleStepPlaceSubmit,
  },
  { Component: StepType },
  { Component: StepMeters },
  { Component: StepRooms },
  {
    Component: StepResult,
    className: 'step--in-middle step--end',
  },
];

const changeData = (
  data: PredictionFormData[],
  current: any,
  index: number,
) => {
  const clone = [...data];
  clone[index] = current;
  return clone;
};

export const Steps = ({ onChange }: Props): JSX.Element => {
  const [index, setIndex] = useState<number>(0);
  const [data, setData] = useState<any>(steps.map(() => null));
  const [sending, setSending] = useState(false);
  const { Component, className, onSubmit } = steps[index];
  const submitData = onSubmit || ((d: PredictionFormData) => d);
  const reduced = reduceData(data);
  const { place } = reduced;
  const isFirst = index === 0;
  const isLast = data.length - 1 === index;
  const isBadCommune = !(isFirst || place.valid);

  useEffect(() => {
    if (isLast) {
      sendGAEvent('last_step', {
        event_label: 'Último paso: predcción',
        event_category: 'flow',
      });
    }

    if (isBadCommune) {
      sendGAEvent('bad_commune', {
        event_label: 'Comuna fuera del area de predicción',
        event_category: 'flow',
      });
    }
  }, [index]);

  const handleNext = (current: PredictionFormData) => {
    setSending(true);
    makeAsync(submitData(current))
      .then((current: PredictionFormData) => {
        const changed = changeData(data, current, index);
        setSending(false);
        setData(changed);
        setIndex(index + 1);
        onChange(index + 2, changed);
      })
      .catch((err: any) => console.log(err));
  };

  const handleChange = (i: number) => {
    const changed = changeData(data, null, i);
    onChange(i + 1, changed);
    setIndex(i);
  };

  const handleReset = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onChange(1, steps.map(() => null) as any);
    setIndex(0);
  };

  return (
    <>
      <div className="steps">
        {!isBadCommune && (
          <Step current={data[index]} onNext={handleNext} className={className}>
            <Component data={reduced} onReset={handleReset} sending={sending} />
          </Step>
        )}
        {isBadCommune && (
          <article className="prediction__warning-msg">
            <div className="prediction__warning-msg__content">
              {place.validationMessage && (
                <div className="mar">
                  <h4 className="prediction__warning-msg__title">
                    {place.validationMessage.title}
                  </h4>
                  {place.validationMessage.parragraphs.map(
                    (content: string, i: number) => {
                      return <p key={i}>{content}</p>;
                    },
                  )}
                </div>
              )}
              <div className="text-center">
                <button
                  onClick={handleReset}
                  className="prediction__back-button"
                >
                  Volver
                </button>
              </div>
            </div>
          </article>
        )}
      </div>
      <StepsNavigation
        steps={data}
        current={index}
        onChange={handleChange}
        enabled={isFirst || place.valid}
      />
    </>
  );
};
