interface configurationInterface {
  [index: string]: boolean | string | undefined;
  SHOW_DOWNLOAD_BUTTON?: boolean;
  DOWNLOAD_BUTTON_URL?: string;
}

const checkLiteralTrue = (str: string | undefined): boolean =>
  str !== undefined ? /true/i.test(str) : false;

const configurations: configurationInterface = {
  SHOW_DOWNLOAD_BUTTON: checkLiteralTrue(
    process.env.REACT_APP_SHOW_DOWNLOAD_BUTTON,
  ),
  DOWNLOAD_BUTTON_URL: process.env.REACT_APP_API_URL + '/appraisal',
};

export default configurations;
