import React, { Fragment } from 'react';
import './Breadcrumbs.scss';
import { PinIcon, FlatIcon, HouseIcon, RuleIcon, BedroomIcon } from './icons';

interface BreadcrumbsProps {
  data: any;
  highlight?: boolean;
}

const Breadcrumbs = ({
  data,
  highlight = false,
}: BreadcrumbsProps): JSX.Element => {
  const values = data ? getKeyValueArr(data) : [];
  const items = values.map(BreadcrumbsItem).filter(Boolean);
  let className = 'breadcrumbs';
  if (highlight) className += ' breadcrumbs--highlight';

  return (
    <div className={className}>
      <ul className="row gut-sm fit">{items}</ul>
    </div>
  );
};

const getKeyValueArr = (data: any[]) => {
  return Object.keys(data).map((key: any) => {
    return { key, value: data[key] };
  });
};

const BreadcrumbsItem = (data: Object, i: number): JSX.Element => {
  const Icon = getIcon(data);
  const Content = getContent(data);
  return (
    Content && (
      <li key={i}>
        <div className="breadcrumbs__item">
          {Icon && <Icon className="breadcrumbs__item__icon" />}
          {Content && <Content className="breadcrumbs__item__content" />}
        </div>
      </li>
    )
  );
};

const getIcon = (() => {
  const icons: any = {
    place: PinIcon,
    type: {
      CASA: HouseIcon,
      DEPARTAMENTO: FlatIcon,
    },
    meters: RuleIcon,
    rooms: BedroomIcon,
  };

  return ({ key, value }: any) => {
    const icon = icons[key];
    if (icon) {
      if (key === 'type') return icon[value];
      return icon;
    }
    return null;
  };
})();

const toShortDescription = (str: string) => {
  return str
    .replace(', Chile', '')
    .replace('Avenida', 'Av.')
    .replace('Pasaje', 'Psj.');
};

const getContent = (() => {
  const types: any = {
    CASA: 'Casa',
    DEPARTAMENTO: 'Departamento',
  };
  const contents: any = {
    place: (value: any) => (
      <Fragment>
        <h4>Lugar</h4>
        <p>{toShortDescription(value.description)}</p>
      </Fragment>
    ),
    type: (value: any) => (
      <Fragment>
        <h4>Tipo</h4>
        <p>{types[value]}</p>
      </Fragment>
    ),
    meters: (value: any) => (
      <Fragment>
        <h4>Metros</h4>
        <p>
          <span>Total: {value.total}m²</span>
          <span>&emsp;</span>
          <span>Útil: {value.util}m²</span>
        </p>
      </Fragment>
    ),
    rooms: (value: any) => (
      <Fragment>
        <h4>Habitaciones</h4>
        <p>
          <span>Dormitorios: {value.bedrooms}</span>
          <span>&emsp;</span>
          <span>Baños: {value.bathrooms}</span>
        </p>
      </Fragment>
    ),
  };

  return ({ key, value }: any) => {
    const fun = contents[key] || null;
    const content = fun && (fun(value) || null);
    return (
      content &&
      ((props: any) => {
        return <div {...props}>{content}</div>;
      })
    );
  };
})();

BreadcrumbsItem.displayName = 'BreadcrumbsItem';
Breadcrumbs.displayName = 'Breadcrumbs';
export { Breadcrumbs };
