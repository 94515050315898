import { PredictionFormData, PredictionInput } from 'api/schemas';

export const convertToPredictInput = (
  formData: PredictionFormData,
): PredictionInput => {
  const { place, type, meters, rooms } = formData;
  const formatted_address = place?.geocode?.formatted_address;
  const m2_util = parseInt(meters?.util ?? '1');
  const m2_terreno = parseInt(meters?.total ?? '1');
  const comuna =
    place?.geocode.address_components.find((c) =>
      c.types.includes('administrative_area_level_3'),
    )?.short_name ?? '';
  const latitude = parseFloat(`${place?.geocode.geometry.location.lat}`);
  const longitude = parseFloat(`${place?.geocode.geometry.location.lng}`);
  return {
    formatted_address,
    comuna,
    m2_util,
    m2_terreno,
    tipo: type ?? 'DEPARTAMENTO',
    latitude,
    longitude,
    dormitorios: parseInt(rooms?.bedrooms ?? ''),
    banos: parseInt(rooms?.bathrooms ?? ''),
  };
};
