import React, { useState, Fragment } from 'react';
import { RadioButton, NextButton } from './common';
import { HouseIcon, FlatIcon } from './icons';

import './StepType.scss';

const StepType = (): JSX.Element => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const handleSelect = (value: string) => setEnabled(!!value);
  return (
    <article className="step-type">
      <div className="step-type__options row middle gut-xs">
        <p className="label">Tipo de propiedad</p>
        <StepTypeRadioSelect onSelect={handleSelect} />
        <div className="fit step__next-holder">
          <NextButton disabled={!enabled} />
        </div>
      </div>
    </article>
  );
};

const StepTypeRadioSelect = ({
  onSelect,
}: {
  onSelect: Function;
}): JSX.Element => {
  const handleChange = (e: any) => onSelect(e.target.value);
  return (
    <Fragment>
      <div>
        <RadioButton
          name="type"
          value="CASA"
          Icon={HouseIcon}
          onChange={handleChange}
        >
          Casa
        </RadioButton>
      </div>
      <div>
        <RadioButton
          name="type"
          value="DEPARTAMENTO"
          Icon={FlatIcon}
          onChange={handleChange}
        >
          Departamento
        </RadioButton>
      </div>
    </Fragment>
  );
};

StepTypeRadioSelect.displayName = 'StepTypeRadioSelect';
StepType.displayName = 'StepType';
export { StepType };
