const service = new google.maps.places.AutocompleteService();

const bounds = new google.maps.LatLngBounds(
	{ lat: -33.640269, lng: -70.890417 }, // SouthWest
	{ lat: -33.234434, lng: -70.426132 }, // NorthEast
);

export const placeAutocomplete = async (input:string, delay:number) => {
	await trhottler(delay);
	return await placeAutocompletePromise({ bounds, input });
};

const placeAutocompletePromise = (options:any) => {
	return new Promise((resolve, reject) => {
		service?.getPlacePredictions(options, (result:any, status:any) => {
			if (status === 'OK') resolve(result);
			else reject(result);
		});
	});
};

const trhottler = (delay:number) => {
	let timer:any = null;
	return new Promise((resolve, reject) => {
		if (timer) clearTimeout(timer);
		timer = setTimeout(resolve, delay);
	});
};