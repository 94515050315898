import { API_URL, ENABLE_RECAPTCHA, RECAPTCHA_SITE_KEY } from './environment';

export default async function report(id: string): Promise<any> {
  let headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (ENABLE_RECAPTCHA) {
    const recaptchaToken = await grecaptcha.enterprise.execute(
      RECAPTCHA_SITE_KEY,
      { action: 'GET_REPORT' },
    );
    headers = {
      ...headers,
      'X-ReCaptcha-Token': recaptchaToken,
      'X-ReCaptcha-Site-Key': RECAPTCHA_SITE_KEY,
    };
  }
  const url = `${API_URL}/appraisal/${id}/report`;
  return await fetch(url, { headers });
}
