import React, { SyntheticEvent } from 'react';
import './Step.scss';

const Step = ({ onNext, children, current, ...attrs }: any): JSX.Element => {
  attrs = { ...attrs, className: 'step ' + attrs.className };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    const entries = new FormData(target);
    const data = Object.fromEntries(entries);
    const nestedData = nest(data);
    onNext(nestedData);
  };

  return (
    <div {...attrs}>
      <form className="step__form" onSubmit={handleSubmit}>
        {children}
      </form>
    </div>
  );
};

const nest = (data: any) => {
  const out = {};
  const deep = (obj: any, keys: any[], value: any) => {
    const key = keys.shift();
    if (keys.length) {
      obj[key] = obj[key] || {};
      deep(obj[key], keys, value);
    } else obj[key] = value;
  };

  Object.keys(data).map((key: string) => {
    const subkeys = key.split('.');
    const value = data[key];
    deep(out, subkeys, value);
  });

  return out;
};

Step.displayName = 'Step';
export { Step };
