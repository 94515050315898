import React from 'react';
import { Prediction } from './Prediction';
import { Newsletter } from './Newsletter';

const Main = (): JSX.Element => {
  return (
    <main>
      <h1 className="occult">Real Estate</h1>
      <Prediction />
      <Newsletter />
    </main>
  );
};

Main.displayName = 'Main';
export { Main };
