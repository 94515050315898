import React from 'react';
import { ChevronIcon } from '../icons';
import { SubmitButton } from './SubmitButton';
import './NextButton.scss';

interface NextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  loading?: boolean;
}

const NextButton = ({
  children,
  loading,
  ...attrs
}: NextButtonProps): JSX.Element => {
  return (
    <SubmitButton className="next-button" loading={loading} {...attrs}>
      {children && <p className="mar-no">{children}</p>}
      <ChevronIcon className="next-button__icon" />
    </SubmitButton>
  );
};

NextButton.displayName = 'NextButton';
export { NextButton };
