import React, { SyntheticEvent, useState } from 'react';
import { TextInput } from './TextInput';
import './SuggestInput.scss';

interface SuggestInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onSelectFunction: (data: any) => void;
  onSuggest: (result: any) => void;
  suggester: (value: any) => Promise<any>;
  minChars: number;
}

const SuggestInput = ({
  onSelectFunction,
  onSuggest,
  suggester,
  minChars,
  ...attrs
}: SuggestInputProps): JSX.Element => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([] as any);

  const handleItemSelect = (data: any) => {
    setOptions([]);
    setValue(data.description);
    onSelectFunction(data);
  };

  const showSuggestions = (value: string) => {
    if (value.length > minChars) {
      suggester(value).then((result: any) => {
        const options = getOtionsItems(result, handleItemSelect);
        onSuggest(result);
        setOptions(options);
      });
    } else setOptions([]);
  };

  const handleChange = showSuggestions;

  const handleBlur = () => {
    // setOptions([])
  };

  const handleFocus = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const { value } = target;
    showSuggestions(value);
  };

  attrs.className = ['suggest-input__input', attrs.className]
    .filter(Boolean)
    .join(' ');

  return (
    <div className="suggest-input">
      <TextInput
        {...attrs}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        autoComplete="off"
      />
      {!!options.length && (
        <div className="suggest-input__options">
          <ul>{options}</ul>
        </div>
      )}
    </div>
  );
};

const getOtionsItems = (suggestions: any[], selectHandler: Function) => {
  return suggestions.map((data: any, i: number) => {
    return <SuggestInputItem key={i} data={data} onSelect={selectHandler} />;
  });
};

const SuggestInputItem = ({ onSelect, data }: any) => {
  const handleClick = () => {
    onSelect(data);
  };
  return <li onClick={handleClick}>{data.description}</li>;
};

SuggestInputItem.displayName = 'SuggestInputItem';
SuggestInput.displayName = 'SuggestInput';
export { SuggestInput };
