import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import report from 'api/report';
import { Button } from './common/Button';
import { sendGAEvent } from './utils/sendGAEvent';

import './DownloadPDFButton.scss';

interface ButtontProps {
  className?: string;
  children?: JSX.Element | JSX.Element[] | string;
  reportId: string;
}

const errorMessge = 'Lo sentimos, no hemos podido cargar el reporte';

const DownloadPDFButton = (props: ButtontProps): JSX.Element => {
  const { reportId, children, className } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [reportUrl, setReportUrl] = useState<null | string>(null);
  const [message, setMessage] = useState<null | string>('Cargando reporte...');
  const handleClick = (_: any) => {
    sendGAEvent('report_download', {
      event_label: 'Descarga del reporte',
      event_category: 'flow',
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await report(reportId);
        if (response.status === 200) {
          const { url } = await response.json();
          setReportUrl(url);
          setMessage(null);
        } else {
          setReportUrl(null);
          setMessage(errorMessge);
        }
      } catch (err) {
        console.log(err);
        setMessage(errorMessge);
      }
      setIsLoading(false);
    })();
  }, [reportId]);

  return (
    <Fragment>
      {message !== null && (
        <div className="text-center download-pdf-button__msg-holder">
          {isLoading && <span className="download-pdf-button__loader"></span>}
          <span>{message}</span>
        </div>
      )}
      {message === null && (
        <Button
          href={reportUrl || ''}
          loading={isLoading}
          onClick={handleClick}
          target="_blank"
          className={clsx('download-pdf-button', className)}
        >
          {children}
        </Button>
      )}
    </Fragment>
  );
};

DownloadPDFButton.displayName = 'DownloadPDFButton';
export { DownloadPDFButton };
