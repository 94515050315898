import React from 'react';
import clsx from 'clsx';
import { Button } from './Button';
import './SubmitButton.scss';

interface SubmitButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  loading?: boolean;
}

const SubmitButton = ({
  children,
  className,
  ...attrs
}: SubmitButtonProps): JSX.Element => {
  return (
    <Button
      type="submit"
      className={clsx('submit-button', className)}
      {...attrs}
    >
      {children}
    </Button>
  );
};

SubmitButton.displayName = 'SubmitButton';
export { SubmitButton };
