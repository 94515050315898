import React, { useEffect, useState } from 'react';
import { PredictionFormData } from 'api/schemas';
import { reduceData } from './utils';
import { Map } from './Map';
import { Breadcrumbs } from './Breadcrumbs';
import { Steps } from './Steps';
import './index.scss';

// TODO: center in place when suggestion is selected instead of submitted

interface StepsDataState {
  current: number;
  values: PredictionFormData[];
}

const Prediction = (): JSX.Element => {
  const [stepsData, setStepsData] = useState<StepsDataState>({
    current: 1,
    values: [],
  });
  const { current: stepsCurrent, values: stepsValues } = stepsData;
  const reducedStepsValues = reduceData(stepsData.values);
  const isLastStep = stepsCurrent === stepsValues.length;
  const geocode = reducedStepsValues.place?.geocode;
  const mapOptions: google.maps.MapOptions = {
    center: geocode?.geometry.location,
    zoom: 11 + (stepsCurrent <= 2 ? stepsCurrent : 1.5 + stepsCurrent * 0.5),
    keyboardShortcuts: false,
  };
  const mapPanBy = isLastStep ? [0, -140] : [0, 0];

  const handleChangeStep = (current: number, values: PredictionFormData[]) => {
    setStepsData({ current, values });
  };

  return (
    <section className="prediction">
      <h2 className="occult">Predecir el valor de la propiedad</h2>
      <Map className="prediction__map" options={mapOptions} panBy={mapPanBy} />
      <Steps onChange={handleChangeStep} />
      <Breadcrumbs data={reducedStepsValues} />
    </section>
  );
};

Prediction.displayName = 'Prediction';
export { Prediction };
