import React from 'react';
import './RadioButton.scss';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  Icon: any;
}

const RadioButton = ({
  children,
  Icon,
  ...attrs
}: RadioButtonProps): JSX.Element => {
  return (
    <label className="radio-button">
      <input type="radio" {...attrs} />
      <div className="radio-button__content">
        {Icon && <Icon className="radio-button__icon" />}
        <p className="mar-no">{children}</p>
      </div>
    </label>
  );
};

RadioButton.displayName = 'RadioButton';
export { RadioButton };
