import { RECAPTCHA_SITE_KEY, ENABLE_RECAPTCHA } from './environment';

export const getRecaptchaHeaders = async (action: string): Promise<any> => {
  if (ENABLE_RECAPTCHA) {
    const token = await grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, {
      action,
    });
    return {
      'X-ReCaptcha-Token': token,
      'X-ReCaptcha-Site-Key': RECAPTCHA_SITE_KEY,
    };
  }
  return {};
};
