import React, {
    Component,
    createRef,
    DetailedHTMLProps,
    HTMLAttributes,
    RefObject,
} from 'react'
import { pickBy } from 'lodash'
import styles from './styles'

const defaultOptions = {
    styles,
    zoom: 12,
    center: { lat: -33.4372786, lng: -70.635462 },
    disableDefaultUI: true,
}

type MapProps = {
    options: google.maps.MapOptions
    panBy: number[]
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export class Map extends Component<MapProps> {
    static defaultProps = {
        options: defaultOptions,
    } as Partial<MapProps>

    ref: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
    gmap?: google.maps.Map<HTMLDivElement>
    marker?: google.maps.Marker

    initGmap = (): void => {
        if (this.ref.current !== null && this.gmap === undefined) {
            this.gmap = new google.maps.Map(this.ref.current)
        }
    }

    componentDidMount() {
        this.initGmap()
        if (this.gmap) {
            this.gmap.setOptions(this.getOptions())
            this.marker = new google.maps.Marker()
        }
    }

    getOptions = (): google.maps.MapOptions => {
        const { options } = this.props as any
        const definedOptions = pickBy(options, (d) => d !== undefined)

        return {
            ...defaultOptions,
            ...definedOptions,
        }
    }

    getZoomFactor = (prev: any, curr: any): number => {
        prev = prev || 1
        curr = curr || 1
        const div = Math.abs(curr - prev) || 1
        const out = 1 / Math.pow(div + 0.5, 2) + 0.125
        return out
    }

    componentDidUpdate({
        options: prevOptions,
        panBy: prevPanBy,
    }: MapProps): void {
        const {
            options: { zoom, center },
            panBy,
        } = this.props
        const { zoom: prevZoom, center: prevCenter } = prevOptions
        const { zoom: defZoom, center: defCenter } = defaultOptions

        if (this.gmap !== undefined) {
            if (prevPanBy[0] !== panBy[0] || prevPanBy[1] !== panBy[1]) {
                const zoomFactor = this.getZoomFactor(prevZoom, zoom)
                if (panBy[0] === 0 && panBy[1] === 0) {
                    this.gmap.panTo(center ?? defCenter)
                } else {
                    this.gmap.panBy(
                        (panBy[0] - prevPanBy[0]) * zoomFactor,
                        (panBy[1] - prevPanBy[1]) * zoomFactor
                    )
                }
            }
            if (prevZoom !== zoom) {
                this.gmap.setZoom(zoom ?? defZoom)
            }
            if (
                prevCenter?.lat !== center?.lat ||
                prevCenter?.lng !== center?.lng
            ) {
                this.gmap.panTo(center ?? defCenter)
                if (center)
                    this.marker?.setOptions({
                        position: center,
                        map: this.gmap,
                    })
                else this.marker?.setMap(null)
            }
        }
    }

    getPlaceDetails = (
        placeId: string
    ): Promise<google.maps.places.PlaceResult> => {
        return new Promise((resolve, reject) => {
            if (this.gmap === undefined) {
                reject()
            } else {
                const service = new google.maps.places.PlacesService(this.gmap)
                const options: google.maps.places.PlaceDetailsRequest = {
                    placeId,
                    fields: ['geometry'],
                }
                service.getDetails(
                    options,
                    (
                        result: google.maps.places.PlaceResult,
                        status: google.maps.places.PlacesServiceStatus
                    ): void => {
                        if (status === 'OK') resolve(result)
                        else reject(result)
                    }
                )
            }
        })
    }

    render() {
        const { children, options, panBy, ...rest } = this.props
        return <div {...rest} ref={this.ref}></div>
    }
}
