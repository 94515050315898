import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import predict from 'api/predict';
import { useConfiguration } from 'configurationHooks';
import { PredictionFormData, PredictionOutput } from 'api/schemas';
import { addThousandSeparator, convertToPredictInput } from './utils';
import { DownloadPDFButton } from './DownloadPDFButton';
import { ErrorIcon, SuccessIcon, DownloadIcon } from './icons';

import './StepResult.scss';
interface ResultStepProps {
  data: PredictionFormData;
  onReset(): any;
}

interface ResultProps {
  prediction: PredictionOutput | undefined;
}

function formatPrediction(value: number): string {
  return addThousandSeparator(Math.round(value));
}

const StepResult = ({ data, onReset }: ResultStepProps): JSX.Element => {
  const [reportId, setReportId] = useState('');
  const [showButtonFeatureValue] = useConfiguration('SHOW_DOWNLOAD_BUTTON');
  const [state, setState] = useState<'none' | 'loading' | 'ready' | 'error'>(
    'none',
  );
  const [prediction, setPrediction] = useState<PredictionOutput>();
  const predictionData = convertToPredictInput(data);
  const className = 'step-prediction step-prediction--' + state;

  useEffect(() => {
    if (state === 'none') {
      setState('loading');
      predict(predictionData)
        .then((predictResponse) => {
          setPrediction(predictResponse);
          setReportId(predictResponse.appraisal_id);
          setState('ready');
        })
        .catch(() => {
          setState('error');
        });
    }
  }, [data]);

  return (
    <TransitionGroup>
      {state === 'ready' && (
        <CSSTransition
          classNames="step-prediction__transition"
          key="result"
          timeout={{ enter: 500, exit: 0 }}
        >
          <div>
            <article className={className}>
              <Result prediction={prediction} />
            </article>
            <div className="prediction__back-button-holder">
              <div className="row gut-sm center middle">
                <div className="fit mar-sm">
                  <button
                    onClick={onReset}
                    className="prediction__back-button"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Volver al inicio
                  </button>
                </div>
                {showButtonFeatureValue && (
                  <div className="fit mar-sm">
                    <DownloadPDFButton reportId={reportId}>
                      <DownloadIcon />
                      <span>Descargar reporte</span>
                    </DownloadPDFButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
      {state === 'loading' && (
        <CSSTransition
          classNames="step-prediction__transition"
          key="loading"
          timeout={{ enter: 300, exit: 0 }}
        >
          <article className={className}>
            <Loading />
          </article>
        </CSSTransition>
      )}
      {state === 'error' && (
        <CSSTransition
          classNames="step-prediction__transition"
          key="loading"
          timeout={{ enter: 300, exit: 0 }}
        >
          <article className={className}>
            <Error />
          </article>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

StepResult.displayName = 'StepResult';

const Result = ({ prediction }: ResultProps) => {
  const [ready, setReady] = useState(false);
  const [min, result, max] = false ||
    prediction?.result.map(formatPrediction) || [0, '--', 0];
  const className = clsx(
    'step-prediction__result',
    ready && 'step-prediction__result--ready',
  );

  setTimeout(() => setReady(true), 100);
  return (
    <div className={className}>
      <div className="row">
        <div className="step-prediction__result-icon">
          <SuccessIcon />
        </div>
        <div className="step-prediction__result-content">
          <p className="step-prediction__result-price">
            <span className="step-prediction__result-price__value">
              {result}
            </span>
            <span className="step-prediction__result-price__symbol">UF</span>
          </p>
          <p className="step-prediction__result-range">
            <span>
              Entre {min} UF y {max} UF
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

Result.displayName = 'Result';

const Loading = () => {
  return (
    <div className="step-prediction__loading">
      <p className="step-prediction__loading__dots">
        <span className="step-prediction__loading__dots__dot">&nbsp;</span>
        <span className="step-prediction__loading__dots__dot">&nbsp;</span>
        <span className="step-prediction__loading__dots__dot">&nbsp;</span>
      </p>
      <p className="step-prediction__loading__message">Cargando predicción</p>
    </div>
  );
};

Loading.displayName = 'Loading';

const Error = () => {
  return (
    <div className="step-prediction__error">
      <ErrorIcon className="step-prediction__error__icon" />
      <p className="step-prediction__error__message">
        <b>Ha ocurrido un error</b>
        <br />
        <span>Lamentamos el inconveniente. Vuelve a intentarlo más tarde.</span>
      </p>
    </div>
  );
};

Error.displayName = 'Error';

export { StepResult };
