import { API_URL } from './environment';
import { getRecaptchaHeaders } from './getRecaptchaHeaders';

export default async (newsletterInput: any): Promise<any> => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(await getRecaptchaHeaders('NEWSLETTER_SUBSCRIBE')),
  };
  const url = `${API_URL}/newsletter-subscriptions`;
  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(newsletterInput),
  });
  return await response.json();
};
