import React from 'react';
import { ReactComponent as CheckIcon } from './icons/check.svg';
import './Message.scss';

interface MessageProps {
  visible: boolean;
  success?: boolean;
  fail?: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const Message = ({
  visible,
  success,
  fail,
  children,
  onClose,
}: MessageProps): JSX.Element | null => {
  const handleClick = (e: any) => {
    e.preventDefault();
    onClose();
  };
  const className = [
    'newsletter-message',
    success && 'newsletter-message--success',
    fail && 'newsletter-message--fail',
  ]
    .filter(Boolean)
    .join(' ');
  return (
    (visible || null) && (
      <div className={'container-sm'}>
        <div className={className}>
          <a
            href="#close-newsletter-message"
            className="newsletter-message__close"
            onClick={handleClick}
          >
            <span className="occult">Cerrar mensaje</span>
          </a>
          {success && (
            <i className="newsletter-message__icon">
              <CheckIcon />
            </i>
          )}
          <div className="newsletter-message__content">{children}</div>
        </div>
      </div>
    )
  );
};

Message.displayName = 'Message';
export { Message };
