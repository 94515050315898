import React, { useState } from 'react';
import { Place } from 'api/schemas';
import { placeAutocomplete } from './services';
import { SuggestInput, NextButton } from './common';
import './StepPlace.scss';

interface StepPlaceProps {
  sending: boolean;
}

const StepPlace = ({ sending }: StepPlaceProps): JSX.Element => {
  const [place, setPlace] = useState(null as any);
  const [placeFallback, setPlaceFallback] = useState(null as any);
  const [enabled, setEnabled] = useState<boolean>(false);
  const handleSuggester = (inputValue: string) => {
    const autocompletePromise = placeAutocomplete(inputValue, 500);
    autocompletePromise.then((autocompleteValues: any) => {
      setEnabled(inputValue.length > 3 && autocompleteValues?.length);
    });
    return autocompletePromise;
  };
  const handleSuggest = (places: any) => {
    setPlaceFallback(places[0] || null);
  };
  const handleSelect = (place: Place) => setPlace(place);

  return (
    <article className="place-step">
      <div className="row middle gut-xs">
        <label htmlFor="place">Ingresa una dirección</label>
        <div>
          <SuggestInput
            id="place"
            name="place.input"
            minChars={2}
            suggester={handleSuggester}
            onSuggest={handleSuggest}
            onSelectFunction={handleSelect}
          />
          <input
            type="hidden"
            name="place.description"
            value={place?.description || placeFallback?.description || ''}
          />
          <input
            type="hidden"
            name="place.id"
            value={place?.place_id || placeFallback?.place_id || ''}
          />
        </div>
        <div className="fit">
          <NextButton loading={sending} disabled={!enabled} />
        </div>
      </div>
    </article>
  );
};

StepPlace.displayName = 'StepPlace';
export { StepPlace };
