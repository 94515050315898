import React from 'react';
import './Footer.scss';

const Footer = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="container-lg">
        <p className="text-center text-sm mar-sm">Tasa.la &copy; 2021</p>
        <div className="footer__legal text-center">
          <p className="text-sm mar-no">
            Este sitio es protegido por reCAPTCHA Enterprise y se aplican la{' '}
            <a href="https://policies.google.com/privacy">
              Política de Privacidad
            </a>{' '}
            y las{' '}
            <a href="https://policies.google.com/terms">
              Condiciones del Servicio
            </a>{' '}
            de Google.
          </p>
        </div>
      </div>
    </footer>
  );
};

Footer.displayName = 'Footer';
export { Footer };
