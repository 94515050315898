import { API_URL } from './environment'
import { getRecaptchaHeaders } from './getRecaptchaHeaders'

export default async function geocode(
    placeId: string
): Promise<google.maps.GeocoderResult> {
    const recaptchaHeaders = await getRecaptchaHeaders('GEOCODE_ADDRESS')
    const headers: HeadersInit = {
        Accept: 'application/json',
        ...recaptchaHeaders,
    }
    const response = await fetch(`${API_URL}/geocode?place_id=${placeId}`, {
        headers,
    })
    const results: google.maps.GeocoderResult = await response.json()

    return results
}
