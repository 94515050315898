import React, { Fragment } from 'react';
import clsx from 'clsx';
import style from './Button.module.scss';

interface ButtonProps extends React.BaseHTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  href?: string;
}

const Button = ({
  href,
  children,
  loading,
  disabled,
  className: baseClassName,
  ...attrs
}: ButtonProps): JSX.Element => {
  const classes = baseClassName?.split(' ').map((d) => style[d] || d);
  const className = clsx(style.button, classes);
  const content = (
    <Fragment>
      {loading && <span className={style.loader} />}
      {!loading && children}
    </Fragment>
  );
  return href ? (
    <a href={href} className={className} {...attrs}>
      {content}
    </a>
  ) : (
    <button className={className} disabled={disabled} {...attrs}>
      {content}
    </button>
  );
};

Button.displayName = 'Button';
export { Button };
