import React, { useState } from 'react'
import { InputBox, NextButton } from './common'
import { areAllNumbers } from './utils'
import { BedroomIcon, BathroomIcon } from './icons'

import './StepRooms.scss'

export const StepRooms = () => {
    const [enable, setEnable] = useState<boolean>(false)
    const handleChange = (values: string[]) => {
        const allNumbers = areAllNumbers(values)
        setEnable(allNumbers)
    }
    return (
        <article className="step-rooms">
            <div className="row middle gut-xs">
                <p className="label">Habitaciones</p>
                <StepRoomsInputs onChange={handleChange} />
                <div className="fit step__next-holder">
                    <NextButton disabled={!enable} />
                </div>
            </div>
        </article>
    )
}

const StepRoomsInputs = ({ onChange }: any) => {
    const [values, setValues] = useState<string[]>(['', ''])
    const handleChange = (i: number) => {
        return (e: any) => {
            values.splice(i, 1, e.target.value)
            setValues([...values])
            onChange([...values])
        }
    }
    return (
        <>
            <div className="step-rooms__input-holder">
                <InputBox
                    name="rooms.bedrooms"
                    type="number"
                    min="1"
                    max="99"
                    onChange={handleChange(0)}
                    small
                >
                    <BedroomIcon />
                    <span>Dormitorios</span>
                </InputBox>
            </div>
            <div className="step-rooms__input-holder">
                <InputBox
                    name="rooms.bathrooms"
                    type="number"
                    min="1"
                    max="99"
                    onChange={handleChange(1)}
                    small
                >
                    <BathroomIcon />
                    <span>Baños</span>
                </InputBox>
            </div>
        </>
    )
}
