import React from 'react';
import { StepProps } from 'api/schemas';

import './StepsNavigation.scss';

interface StepsNavigationProps {
  steps: StepProps[];
  current: number;
  onChange?: any;
  enabled?: boolean;
}

interface StepsNavigationItemProps {
  index: number;
  current: number;
  onClick: any;
  enabled?: boolean;
}

const StepsNavigationItem = ({
  index,
  current,
  onClick,
  enabled,
}: StepsNavigationItemProps): JSX.Element => {
  const isCurrent = index === current;
  const className = [
    'steps-navigation__item',
    isCurrent && 'steps-navigation__item--current',
    !enabled && 'steps-navigation__item--disabled',
  ]
    .filter(Boolean)
    .join(' ');
  const handleClick = (e: any) => {
    e.preventDefault();
    enabled && onClick(index);
  };
  return (
    <a className={className} href={`#step-${index + 1}`} onClick={handleClick}>
      <span className="occult">{index + 1}</span>
    </a>
  );
};

const StepsNavigation = ({
  steps,
  current,
  onChange,
  enabled,
}: StepsNavigationProps): JSX.Element => {
  const handleClick = (i: number) => {
    onChange && onChange(i, steps[i]);
  };
  return (
    <nav className="steps-navigation">
      {steps.map((step, i) => (
        <StepsNavigationItem
          key={i}
          index={i}
          current={current}
          onClick={handleClick}
          enabled={(enabled && !!step) || current >= i}
        />
      ))}
    </nav>
  );
};

StepsNavigationItem.displayName = 'StepsNavigationItem';
StepsNavigation.displayName = 'StepsNavigation';
export { StepsNavigation };
