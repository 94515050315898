import React, { useState, SyntheticEvent } from 'react';

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: any;
  onChange: any;
}

const TextInput = ({
  value,
  onChange,
  ...attrs
}: TextInputProps): JSX.Element => {
  const [initialValue, setInitialValue] = useState(value || '');
  const [innerValue, setInnerValue] = useState(value || '');

  const handleChange = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const { value } = target;
    setInnerValue(value);
    onChange(value);
  };

  if (initialValue !== value) {
    setInitialValue(value);
    setInnerValue(value);
  }
  return (
    <input type="text" value={innerValue} onChange={handleChange} {...attrs} />
  );
};

TextInput.displayName = 'TextInput';
export { TextInput };
