import React, { useState } from 'react';
import './FormInput.scss';

interface FormInputProps {
  name: string;
  label: string;
  validate: (value: any) => boolean;
  onChange: (value: any) => void;
}

const FormInput = ({
  name,
  label,
  validate,
  onChange,
}: FormInputProps): JSX.Element => {
  const [isValid, setIsValid] = useState(true);
  const className = [
    'newsletter-input',
    !isValid && 'newsletter-input--invalid',
  ]
    .filter(Boolean)
    .join(' ');
  const handleBlur = (e: any) => setIsValid(validate(e.target.value));
  const handleFocus = () => setIsValid(true);
  const handleChange = (e: any) => onChange(e.target.value);

  return (
    <label className={className}>
      <p className="newsletter-input__label">{label}</p>
      <input
        className="newsletter-input__input"
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        name={name}
        type="text"
      />
    </label>
  );
};

FormInput.displayName = 'FormInput';
export { FormInput };
