import React, { Fragment, useState } from 'react';
import { Form } from './Form';
import { Message } from './Message';
import { sendGAEvent } from '../Prediction/utils/sendGAEvent';
import newsletterSubscribe from 'api/newsletterSubscribe';

import './Newsletter.scss';

const Newsletter = (): JSX.Element => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showFailMsg, setShowFailMsg] = useState(false);
  const showForm = !showSuccessMsg && !showFailMsg;
  const handleSubmit = (formData: any, submitCallback: any) => {
    newsletterSubscribe(formData)
      .then(({ result }: any) => {
        if (result.success) setShowSuccessMsg(true);
        else setShowFailMsg(true);
      })
      .catch(() => setShowFailMsg(true))
      .finally(submitCallback);
  };
  const handleCloseSuccessMessage = () => setShowSuccessMsg(false);
  const handleCloseFailMessage = () => setShowFailMsg(false);

  if (showSuccessMsg) {
    sendGAEvent('newsletter_send', {
      event_label: 'Inscripción al newsletter',
      event_category: 'flow',
    });
  }

  if (showFailMsg) {
    sendGAEvent('newsletter_error', {
      event_label: 'Error al inscribirse en el newsletter',
      event_category: 'flow',
    });
  }

  return (
    <section className="newsletter">
      <div className="container-md">
        <h2 className="occult">Newsletter</h2>
        <p className="h5 text-light text-center mar">
          Déjanos tu correo y te escribiremos con noticias de tasa.la
        </p>
      </div>
      <Form visible={showForm} onSubmit={handleSubmit} />
      <Message
        visible={showSuccessMsg}
        onClose={handleCloseSuccessMessage}
        success
      >
        <Fragment>
          <h6 className="text-light">Tu información ha sido enviada</h6>
          <p>Pronto recibirás noticias de tasa.la</p>
        </Fragment>
      </Message>
      <Message visible={showFailMsg} onClose={handleCloseFailMessage} fail>
        <Fragment>
          <h6 className="text-light">
            Lo sentimos, ha ocurrido un error al enviar tu información
          </h6>
          <p>Vuelve a intentarlo más tarde</p>
        </Fragment>
      </Message>
    </section>
  );
};

Newsletter.displayName = 'Newsletter';
export { Newsletter };
