import React from 'react';
import { Header } from './Header';
import { Main } from './Main';
import { Footer } from './Footer';
import './App.scss';

const App = (): JSX.Element => {
  return (
    <div className="app">
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

App.displayName = 'App';
export { App };
